.container.contactContainer {
  width: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-top: 2rem;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #fff;
}

.contactOptions {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contactOption {
  background: rgba(0, 0, 0, 0.6);
  padding: 1.5rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.contactOption:hover {
  transform: translateY(-5px);
  border-color: #fa8072;
}

.contactOption-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.contactOption a {
  margin-top: 1rem;
  display: inline-block;
  font-size: 1rem;
  color: #fa8072;
  text-decoration: none;
}

.contactOption a:hover {
  text-decoration: underline;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.35rem;
}

input,
textarea {
  width: 100%;
  padding: 1.2rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #fa8072;
  color: white;
  font-size: 1rem;
}

input::placeholder,
textarea::placeholder {
  color: #aaa;
}

.btn.sendMessage {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  background-color: #fa8072;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn.sendMessage:hover {
  background-color: #ff6347;
}

/* Medium Devices */
@media screen and (max-width: 1024px) {
  .container.contactContainer {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .contactOptions {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
}

/* Small Devices */
@media screen and (max-width: 600px) {
  .container.contactContainer {
    grid-template-columns: 1fr; /* Stack the form and options vertically */
    margin-top: 3rem; /* Extra space for smaller screens */
  }

  .contactOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem; /* Adjust spacing between boxes */
  }

  .contactOption {
    flex: 1; /* Makes the boxes take equal space */
    padding: 1.2rem; /* Ensure consistent padding */
    min-width: 150px; /* Prevent shrinking too much */
    text-align: center;
  }

  .contactOption h4,
  .contactOption h5 {
    margin: 0.5rem 0; /* Add spacing between elements */
  }

  .contactOption h5 {
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
  }

  input,
  textarea {
    padding: 1rem;
  }

  .btn.sendMessage {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }
}
