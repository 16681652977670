.about_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 1rem;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 7rem;
  font-size: 2.5rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.skills_Content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.skills_Content p {
  font-size: 1rem;
  text-align: center;
}

.skillIcon {
  width: 80px;
  height: auto;
  transition: transform 0.3s ease;
}

.skillIcon:hover {
  transform: scale(1.1);
}

hr.line,
hr.vLine {
  display: none;
}

.about_Content {
  text-align: center;
  font-size: 1rem;
  padding: 1rem;
  margin: 0 auto; /* Center horizontally */
  max-width: 700px; /* Constrain width */
}

.about_Content p {
  margin-bottom: 1rem;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1rem;
  padding: 1rem;
}

@media screen and (max-width: 1024px) {
  .about_container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .skills_Content {
    gap: 1rem;
  }

  .about_Content {
    padding: 1rem;
    font-size: 0.9rem;
  }

  .about_Content p {
    padding: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  h2 {
    font-size: 1.5rem;
  }

  .skills_Content {
    gap: 0.8rem;
  }

  .skillIcon {
    width: 60px;
  }

  .about_Content p {
    font-size: 0.8rem;
  }
}
