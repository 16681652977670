.portfolio_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  margin: 0 auto;
  padding: 1rem;
  margin-bottom: 2rem;
}

.portfolio_item {
  background: rgba(0, 0, 0, 0.6);
  padding: 1.5rem;
  border-radius: 1.2rem;
  border: 1px solid transparent;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.portfolio_item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.portfolio_img img {
  border-radius: 0.8rem;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.portfolio_item h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
  color: #fff;
}

.portfolio_item-cta {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.portfolio_item-cta .btn {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  text-decoration: none;
  background: #fa8072;
  color: #fff;
  transition: background 0.3s ease;
}

.portfolio_item-cta .btn:hover {
  background: #ff624d;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #fff;
}

/* Medium Devices */
@media screen and (max-width: 1024px) {
  .portfolio_container {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 1rem;
  }
}

/* Small Devices */
@media screen and (max-width: 600px) {
  .portfolio_container {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
    margin-bottom: 3rem; /* Extra space for smaller screens */
  }

  .portfolio_item {
    padding: 1rem;
  }

  .portfolio_item-cta {
    flex-direction: column;
  }

  .portfolio_item-cta .btn {
    width: 100%;
    text-align: center;
    font-size: 12px;
  }

  .portfolio_item h3 {
    font-size: 1rem;
  }
}
